var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-alert', {
    staticClass: "alert-message mb-0",
    style: {
      top: "".concat(_vm.$vuetify.application.top, "px")
    },
    attrs: {
      "dismissible": "",
      "elevation": "4",
      "type": "error",
      "value": _vm.showAlert,
      "width": "100%"
    }
  }, [_c('span', {
    staticClass: "message-text font-weight-bold"
  }, [_vm._v(" THE LIBRARY WILL BE CLOSED INDEFINITELY!")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }