var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "nav-row d-flex align-center justify-space-between",
    attrs: {
      "color": "transparent"
    }
  }, _vm._l(_vm.navItems, function (navItem) {
    return _c('v-card', {
      key: 'navFlex' + navItem.value,
      staticClass: "pa-1 rounded",
      attrs: {
        "flat": ""
      }
    }, [_c('v-menu', {
      attrs: {
        "open-on-click": "",
        "open-on-hover": "",
        "close-on-click": "",
        "close-on-content-click": _vm.breakpoint.mdAndUp ? true : false,
        "elevation": "0",
        "min-width": "100%",
        "bottom": "",
        "nudge-bottom": "",
        "offset-y": "",
        "transition": "scroll-y-transition"
      },
      on: {
        "input": function input($event) {
          $event === true ? _vm.navItemSelected = navItem.value : _vm.navItemSelected = null;
        }
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on;
          return [_c('v-sheet', _vm._g({
            staticClass: "primary--text d-flex align-center justify-center text-truncate",
            class: "flex-".concat(_vm.menuLabelDirection, " ").concat(_vm.navItemSelected === navItem.value ? 'primary secondary--text' : 'secondary primary--text', " "),
            attrs: {
              "height": "45px",
              "rounded": "",
              "width": "100%"
            },
            on: {
              "mouseover": function mouseover($event) {
                _vm.navItemSelected = navItem.value;
              }
            }
          }, on), [_c('v-icon', {
            attrs: {
              "left": _vm.menuLabelDirection === 'row',
              "color": _vm.navItemSelected === navItem.value ? 'secondary' : 'primary'
            }
          }, [_vm._v(_vm._s(navItem.icon))]), _c('font', {
            staticClass: "font-weight-bold",
            class: _vm.menuLabelDirection === 'column' ? 'caption' : 'ml-1 '
          }, [_vm._v(_vm._s(_vm.menuLabelText(navItem.text)))])], 1)];
        }
      }], null, true)
    }, [_c('v-card', {
      staticClass: "subnav-menu-card mx-auto elevation-0",
      class: _vm.breakpoint.smAndDown ? 'mt-4' : 'mt-4',
      attrs: {
        "color": "secondary",
        "flat": "",
        "rounded": "",
        "width": "98%"
      }
    }, [_c('v-card-text', {
      staticClass: "primary--text subNavPanelCardText"
    }, [_c('v-scale-transition', {
      attrs: {
        "leave-absolute": ""
      }
    }, [_c('v-row', {
      attrs: {
        "justify": "space-around",
        "align": "start"
      }
    }, [_vm.breakpoint.smAndDown ? [_c('v-expansion-panels', {
      staticClass: "primary--text",
      attrs: {
        "accordion": "",
        "rounded": ""
      },
      model: {
        value: _vm.subNavExpansionPanel,
        callback: function callback($$v) {
          _vm.subNavExpansionPanel = $$v;
        },
        expression: "subNavExpansionPanel"
      }
    }, _vm._l(navItem.subnav, function (subLinks) {
      return _c('v-expansion-panel', {
        key: subLinks.text,
        staticClass: "primary--text"
      }, [_c('v-expansion-panel-header', {
        scopedSlots: _vm._u([{
          key: "actions",
          fn: function fn() {
            return [_c('v-icon', {
              attrs: {
                "color": "primary"
              }
            }, [_vm._v("$vuetify.icons.expand")])];
          },
          proxy: true
        }], null, true)
      }, [_c('v-sheet', {
        staticClass: "d-flex align-center justify-start primary--text",
        attrs: {
          "color": "transparent",
          "width": "100%",
          "height": "100%"
        }
      }, [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "color": "primary"
        }
      }, [_vm._v(_vm._s(subLinks.icon))]), _vm._v(" " + _vm._s(subLinks.text) + " ")], 1), _c('v-spacer')], 1), _c('v-expansion-panel-content', {
        staticClass: "secondary-nav-panel-content primary--text secondary text-xs-left px-4 py-2"
      }, [_c('ul', _vm._l(subLinks.links, function (link) {
        return _c('li', {
          key: link.text + subLinks.text,
          staticClass: "font-weight-medium py-1",
          style: _vm.highlightSubNavItem === link.text ? 'background-color: rgba(0, 0, 102, 0.35);' : '',
          attrs: {
            "transition": "fade-transition",
            "left": ""
          },
          on: {
            "mouseenter": function mouseenter($event) {
              _vm.highlightSubNavItem = link.text;
            },
            "mouseleave": function mouseleave($event) {
              _vm.highlightSubNavItem = null;
            }
          }
        }, [_vm._v(" " + _vm._s(link.text) + " ")]);
      }), 0)])], 1);
    }), 1)] : _vm._e(), _vm.breakpoint.mdAndUp ? [_vm._l(navItem.subnav, function (subLinks) {
      return [_c('v-col', {
        key: subLinks.text,
        staticClass: "text-xs-left pa-3",
        attrs: {
          "col": "12",
          "sm": "4"
        }
      }, [_c('v-sheet', {
        staticClass: "d-flex align-center pb-2 primary--text",
        attrs: {
          "color": "transparent"
        }
      }, [_c('v-icon', {
        staticClass: "mr-2",
        attrs: {
          "color": "primary"
        }
      }, [_vm._v(_vm._s(subLinks.icon))]), _c('font', {
        key: subLinks.text + 'title',
        staticClass: "title"
      }, [_vm._v(_vm._s(subLinks.text))])], 1), _c('v-divider', {
        key: subLinks.text + 'divider',
        staticClass: "primary--text",
        attrs: {
          "light": ""
        }
      }), [_c('div', {
        staticClass: "text-xs-left mt-1"
      }, [_c('ul', _vm._l(subLinks.links, function (link) {
        return _c('li', {
          key: link.text + subLinks.text,
          staticClass: "font-weight-medium",
          style: _vm.highlightSubNavItem === link.text ? 'background-color: rgba(0, 0, 102, 0.35);' : '',
          attrs: {
            "transition": "fade-transition",
            "left": ""
          },
          on: {
            "mouseenter": function mouseenter($event) {
              _vm.highlightSubNavItem = link.text;
            }
          }
        }, [_vm._v(" " + _vm._s(link.text) + " ")]);
      }), 0)])]], 2)];
    })] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }