var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "d-flex align-center secondary--text",
    attrs: {
      "active-class": "active-override",
      "color": "transparent",
      "flat": "",
      "hover": "",
      "link": false,
      "to": "/about"
    }
  }, [_c('v-tooltip', {
    attrs: {
      "color": "primary",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-icon', _vm._g(_vm._b({
          attrs: {
            "color": "success",
            "large": "",
            "left": ""
          }
        }, 'v-icon', attrs, false), on), [_vm._v("mdi-home-clock")])];
      }
    }])
  }, [_vm.$vuetify.breakpoint.xs ? _c('v-sheet', {
    staticClass: "d-flex flex-column secondary--text",
    attrs: {
      "color": "transparent"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-body-1 text-no-wrap"
  }, [_vm._v("We are open!")]), _c('span', {
    staticClass: "text-subtitle-2 text-no-wrap"
  }, [_vm._v(" 9:00 AM - 9:00 PM")])]) : _vm._e()], 1), _vm.$vuetify.breakpoint.smAndUp ? _c('v-sheet', {
    staticClass: "d-flex flex-column secondary--text",
    attrs: {
      "color": "transparent"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold text-body-1 text-no-wrap"
  }, [_vm._v("We are open!")]), _c('span', {
    staticClass: "text-subtitle-2 text-no-wrap"
  }, [_vm._v(" 9:00 AM - 9:00 PM")])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }